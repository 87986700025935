<template>
  <div>
    <div
      class="d-flex justify-end"
      :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-4'"
      flat
      tile
    >
      <div style="width: 80%"></div>

      <v-select
        class="pa-2"
        outlined
        filled
        dense
        v-model="dateRange"
        @input="(dateRange) => dateRangeChanged(dateRange)"
        :items="[
          'This Month',
          'Last Month',
          'Today',
          'Yesterday',
          'This Year',
          'Last Year',
          'Custom Date',
        ]"
      ></v-select>
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="date"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="date"
            label="Custom Date"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker range v-model="date" no-title scrollable>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
          <v-btn text color="primary" @click="dateRangeChanged(date, 'customDate')">
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
    </div>
    <v-card>
      <v-card-title>
        {{ translate("Expenses") }}
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        sort-by.sync="status"
        sort-desc.sync="true"
        :items-per-page="20"
        :search="search"
        :headers="headers"
        :items="getProducts"
        class="elevation-1"
      >
      <template v-slot:footer.page-text>
       <h4>Total: {{ sum }}</h4>
      </template>
    </v-data-table>
        <template v-slot:item.status="{ item }">
          <span class="font-weight-medium v-chip v-size--small" :class="item.color"
            ><span class="v-chip__content"> {{ item.status }} </span></span
          >
        </template>
        <template v-slot:item.order_created_at="{ item }">
          <span>{{ formatDate(item.order_created_at) }}</span
          >&nbsp;
          <span class="font-weight-medium v-chip v-size--small primary"
            ><span class="v-chip__content">
              {{ timeAgo(item.order_created_at) }}
            </span></span
          >
        </template>

        <template v-slot:item.date="{ item }">
          <span>{{ formatDate(item.date) }}</span
          >&nbsp;
          <span class="font-weight-medium v-chip v-size--small primary"
            ><span class="v-chip__content"> {{ timeAgo(item.date) }} </span></span
          >
        </template>
        <template v-slot:item.actions="{ item }">
          <router-link :to="{ name: 'edit_order', params: { id: item.id } }">
            <!--
              <v-icon style="color: var(--v-warning-base)" small> mdi-pencil </v-icon>
            -->
          </router-link>
          <v-dialog :retain-focus="false" width="500">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                @click.stop="item.delete_dialog = true"
                small
                style="color: var(--v-error-base)"
                v-bind="attrs"
                v-on="on"
              >
                mdi-delete
              </v-icon>
            </template>

            <v-card>
              <v-card-title class="text-h5"
                >{{ translate("Are you sure want to delete Expense") }}
                {{ item.id }}?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="delete_dialog = false">{{
                  translate("Cancel")
                }}</v-btn>
                <v-btn color="primary" @click="handleDelete(item.id)">{{
                  translate("OK")
                }}</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
      </v-data-table>
    </v-card>
    <div class="text-center">
      <downloadExcel
        class="elevation-0 v-btn v-size--small v-btn--outlined v-btn--tile"
        :data="getProducts"
      >
          Export Excel   
      </downloadExcel>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { ref } from "@vue/composition-api";
import OrderInfo from "@/views/pages/orders/OrderInfo.vue";
import CheckinInfo from "./CheckinInfo.vue";
import { getExpenseTypes } from "@/ApiManager";
import {
  deleteExpense,
  deleteOrder,
  getCheckins,
  getCurrency,
  getExpenses,
} from "@/ApiManager";
export default {
  computed: {
    sum(){
      var sum=0;
      console.log(this.products)

      for(var i=0;i<this.products.length;i++){
        sum=sum+this.products[i]['amount']
      }
      if(sum==0){
        return ''
      }
      return `${sum} Lek`
    },
    getProducts() {
      return this.products;
    },
  },
  setup() {
    const edit_created_date = ref("");
    const edit_customer_name = ref("");
    const edit_customer_status = ref("");
    const edit_magazine = ref("");
    const edit_customer_total_price = ref("");

    return {
      edit_created_date,
      edit_customer_name,
      edit_customer_status,
      edit_magazine,
      edit_customer_total_price,
    };
  },
  data() {
    return {
      expense_type_id: null,
      dateRange: "This Month",
      date: null,
      menu: null,
      search: "",
      dialog: false,
      delete_dialog: false,
      currency: "",
      magazine_names: [],
      expense_types: [],
      headers: [
        { text: "ID", value: "id" },
        { text: this.translate("expense name"), value: "name" },

        { text: this.translate("Created Date"), value: "date" },
        { text: this.translate("notes"), value: "note" },
        { text: this.translate("amount"), value: "amount" },
        { text: this.translate("Actions"), value: "actions" },
      ],
      products: [],
    };
  },
  components: {
    OrderInfo,
    CheckinInfo,
  },
  methods: {
    dateRangeChanged(dateRange, customDate) {
      if (dateRange == "Custom Date" && customDate == undefined) return;
      this.$store.state.loading = true;
      if (customDate != undefined) {
        this.dateRange = "Custom Date";
      }
      getExpenses(dateRange).then((response) => {
        this.products = response.data;
        this.$store.state.loading = false;
      });
    },
    setCheckinData(data) {
      this.products = data;
    },

    handleDelete(id) {
      deleteExpense(id);
      this.delete_dialog = false;
      this.products = this.products.filter(function (item) {
        return item.id != id;
      });
    },
  },
  created() {
    this.$store.state.loading = true;
    getCurrency().then((response) => {
      this.currency = response["currency"];
    });
    getExpenses(this.dateRange).then((response) => {
      this.products = response.data;

      this.products.forEach(
        (product) => (product.amount = `${product.amount} ${this.currency}`)
      );

      this.$store.state.loading = false;
    });

    //getCheckins().then(response => {
    //  this.products = response
    //  this.products.forEach(
    //    product => (
    //      (product.total_paid = `${product.total_paid} ${this.currency}`),
    //      (product.color = product.supplier_paid == 1 ? 'success' : 'error'),
    //      (product.status = product.supplier_paid == 1 ? this.translate('Paid') : this.translate('Not Paid')),
    //      (this.$store.state.loading = false)
    //    ),
    //  )
    //})
  },  
};
</script>

<style>
a {
  text-decoration: none;
}
</style>
