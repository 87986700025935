var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex justify-end",attrs:{"color":_vm.$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-4',"flat":"","tile":""}},[_c('div',{staticStyle:{"width":"80%"}}),_c('v-select',{staticClass:"pa-2",attrs:{"outlined":"","filled":"","dense":"","items":[
        'This Month',
        'Last Month',
        'Today',
        'Yesterday',
        'This Year',
        'Last Year',
        'Custom Date' ]},on:{"input":function (dateRange) { return _vm.dateRangeChanged(dateRange); }},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}}),_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.date,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Custom Date","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"range":"","no-title":"","scrollable":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.dateRangeChanged(_vm.date, 'customDate')}}},[_vm._v(" OK ")])],1)],1)],1),_c('v-card',{scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
      var item = ref.item;
return [_c('span',{staticClass:"font-weight-medium v-chip v-size--small",class:item.color},[_c('span',{staticClass:"v-chip__content"},[_vm._v(" "+_vm._s(item.status)+" ")])])]}},{key:"item.order_created_at",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.order_created_at)))]),_vm._v("  "),_c('span',{staticClass:"font-weight-medium v-chip v-size--small primary"},[_c('span',{staticClass:"v-chip__content"},[_vm._v(" "+_vm._s(_vm.timeAgo(item.order_created_at))+" ")])])]}},{key:"item.date",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.date)))]),_vm._v("  "),_c('span',{staticClass:"font-weight-medium v-chip v-size--small primary"},[_c('span',{staticClass:"v-chip__content"},[_vm._v(" "+_vm._s(_vm.timeAgo(item.date))+" ")])])]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'edit_order', params: { id: item.id } }}}),_c('v-dialog',{attrs:{"retain-focus":false,"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticStyle:{"color":"var(--v-error-base)"},attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();item.delete_dialog = true}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.translate("Are you sure want to delete Expense"))+" "+_vm._s(item.id)+"?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.delete_dialog = false}}},[_vm._v(_vm._s(_vm.translate("Cancel")))]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.handleDelete(item.id)}}},[_vm._v(_vm._s(_vm.translate("OK")))]),_c('v-spacer')],1)],1)],1)]}}])},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.translate("Expenses"))+" "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"sort-by.sync":"status","sort-desc.sync":"true","items-per-page":20,"search":_vm.search,"headers":_vm.headers,"items":_vm.getProducts},scopedSlots:_vm._u([{key:"footer.page-text",fn:function(){return [_c('h4',[_vm._v("Total: "+_vm._s(_vm.sum))])]},proxy:true}])})],1),_c('div',{staticClass:"text-center"},[_c('downloadExcel',{staticClass:"elevation-0 v-btn v-size--small v-btn--outlined v-btn--tile",attrs:{"data":_vm.getProducts}},[_vm._v("   Export Excel    ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }